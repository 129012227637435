import { useEffect, useState } from "react";
import { useAccount, useNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { makeStyles } from "@mui/styles";
import { trimAddress } from "../utils/pureFunctions";
import { useSwitchNetwork } from "wagmi";
import SwitchNetworkPopup from "./Popups/SwitchNetworkPopup";
import { base, mainnet } from "viem/chains";

function Navbar() {
  const classes = useStyles();
  const { isConnected, address: account } = useAccount(); // Get the connected account address
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork();
  const [switchNetworkPopup, setSwitchNetworkPopup] = useState(false);
  const [isSwitchingNetwork, setIsSwitchingNetwork] = useState(false);

  useEffect(() => {
    if (!chain) return;
    if (chain.id !== mainnet.id) {
      setSwitchNetworkPopup(true);
    }
  }, [chain]);

  return (
    <div className={classes.root}>
      <div className={classes.conatiner}>
        <div className="buttons">
          <div onClick={() => open()} className={classes.connectWalletButton}>
            {isConnected && account ? trimAddress(account) : "connect wallet"}
          </div>
        </div>
        {switchNetworkAsync ? (
          <SwitchNetworkPopup
            open={switchNetworkPopup}
            action={async (id) => {
              setIsSwitchingNetwork(true);
              try {
                await switchNetworkAsync(id);
              } catch {}
              setIsSwitchingNetwork(false);
              setSwitchNetworkPopup(false);
            }}
            pending={isSwitchingNetwork}
            showEth
            showBase
          />
        ) : null}
      </div>
    </div>
  );
}

export default Navbar;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 100,
    display: "flex",
    justifyContent: "center",
  },
  conatiner: {
    width: "90%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    color: "white",
  },
  connectWalletButton: {
    backgroundColor: "#181818",
    boxShadow: "inset 3px 3px 12px #4646466A",
    borderRadius: "9px",
    textTransform: "none",
    padding: "8px 16px",

    transition: "all 0.3s ease",
    color: "white",
    "&:hover": {
      boxShadow: "inset 3px 3px 12px #000",
    },
  },
}));
